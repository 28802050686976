<template>
  <form class="form">
    <fieldset class="form__fieldset">
      <legend class="form__legend">Личная информация</legend>
      <div class="form__flex mb-md">
        <div class="row">
          <div class="col-3">
            <FveFileImagePreview
              label=""
              name="avatar"
              v-model="form.avatar"
            />
          </div>
          <div class="col-9">
            <div class="row row--fix">
              <div class="col-4">
                <FveText
                  label="Фамилия"
                  name="last_name"
                  required
                  v-model="form.last_name"
                />
              </div>
              <div class="col-4">
                <FveText
                  label="Имя"
                  name="first_name"
                  required
                  v-model="form.first_name"
                />
              </div>
              <div class="col-4">
                <FveText
                  label="Отчество"
                  name="middle_name"
                  v-model="form.middle_name"
                />
              </div>
              <div class="col-12">
                <FveText
                  label="Роль"
                  name="position"
                  readonly="true"
                  v-model="form.system_role"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
    <fieldset class="form__fieldset">
      <legend class="form__legend">Смена пароля</legend>
      <div class="row">
        <div class="col-12">
          <FvePassword
            label="Новый пароль"
            name="password1"
            required
            v-model="form.password1"
          />
        </div>
        <div class="col-12">
          <FvePassword
            label="Подтвердите пароль"
            name="password2"
            required
            v-model="form.password2"
          />
        </div>
      </div>
    </fieldset>
  </form>
</template>

<script>

import FveText from "@widgetFormValidate/src/Element/Text/FveText";
import FvePassword from "@widgetFormValidate/src/Element/Text/FvePassword";
import FveFileImagePreview from "@widgetFormValidate/src/Element/Text/FveFileImagePreview";

//
import FveFormMixin from "@widgetFormValidate/src/Mixin/FveFormMixin";

export default {
  mixins: [
    FveFormMixin
  ],
  components: {
    FveText,
    FvePassword,
    FveFileImagePreview,
  },
  data(){
    return {
      tagList: [],
    };
  },
  methods: {
    formSchema() {
      return {
        avatar          : { type: FileClass, default: () => { return null; } },
        //
        last_name       : { type: String, default: () => { return ''; } },
        first_name      : { type: String, default: () => { return ''; } },
        middle_name     : { type: String, default: () => { return ''; } },
        //
        system_role     : { type: String, default: () => { return ''; } },
        //
        password1       : { type: String, default: () => { return ''; } },
        password2       : { type: String, default: () => { return ''; } },
      };
    },
  },
};

</script>

<style lang="scss" scoped>

</style>
