<template>
  <div class="container">
    <div class="page-header">
      <div class="page-header__ell">
        <h1 class="page-title">Ваш профиль</h1>
      </div>
    </div>
    <ProfileEditForm :formData="user" ref="ProfileEditForm" />
    <div class="btn-gp">
      <b-button size="lg" variant="danger" @click="save">Сохранить</b-button>
      <router-link :to="{name: this.$routeName.DASHBOARD}" class="btn btn-link btn-lg">Отмена</router-link>
    </div>
  </div>
</template>

<script>

import ProfileEditForm from "@component/Form/ProfileEditForm";

export default {
  components: {
    ProfileEditForm
  },
  data() {
    return {
      user: {},
    };
  },
  methods: {
    async save() {
      let formData = this.$refs.ProfileEditForm.formSubmitGetData();
      if(!formData) { return; }
      let sendData = Object.assign({}, formData);
      RequestManager.Admin.updateProfileMe({data: sendData}).then( (res) => {
        this.$toasts.push({
          message: 'Профиль успешно обновлен',
          duration: 4000,
        });
        this.$router.push({name: this.$routeName.DASHBOARD });
      });
    }
  },
  mounted() {
    RequestManager.Admin.getProfileMe({}).then((data) => {
      this.user = Object.assign({}, data);
      this.user.avatar = new FileClass({src: data.avatar});
    });
  }
};
</script>

<style lang="scss" scoped>

</style>
